import * as React from "react";
import "./App.css";
import Title from "./components/Title/Title";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Contact from "./components/Contact/Contact";
import ScrollToTopButton from "./components/ScrollToTopButton/ScrollToTopButton";
// import { PageMenu } from "./components/PageMenu/PageMenu";

/**
 *
 * TODO:
 * - update styles?
 * - add PageMenu?
 *     - replace react-scroll with page/section navigation?
 * - implement dark/light mode switch
 *
 */

function App() {
  return (
    <div className="app">
      {/* <div className="page-menu">
        <PageMenu />
      </div> */}
      <Title />
      <About />
      <Projects />
      <Contact />
      <ScrollToTopButton />
    </div>
  );
}

export default App;

import * as React from "react";
import GitHubLogo from "../../assets/GitHubLogo.png";
import LinkedInLogo from "../../assets/LinkedInLogo.png";
import EmailIcon from "../../assets/EmailIcon.png";
import "./Contact.css";
import selfie from "../../assets/selfie.jpg";
import { openURL } from "../../utils/helperFunctions";

function Contact() {
  const emailAddress = `tabbathacrouchwork@gmail.com`;
  const subject = `RE: tabbathacrouch.com`;
  const mailToLink = `mailto:${emailAddress}?subject=${subject}`;
  return (
    <div className="contact">
      <h1 className="contact-title">Contact</h1>
      <div className="contact-container">
        <div>
          <img
            className="contact-image"
            src={selfie}
            alt="tabbatha crouch"
            onClick={() => openURL("https://www.instagram.com/tabbathacrouch/")}
          />
        </div>
        <div className="email">
          <a
            className="email-link"
            href={mailToLink}
            target="_blank"
            rel="noreferrer"
          >
            tabbathacrouchwork@gmail.com
          </a>
        </div>
        <div className="social-links">
          <a href={mailToLink} target="_blank" rel="noreferrer" title="Email">
            <img alt="Email icon" src={EmailIcon} width="48px" height="48px" />
          </a>
          <a
            href="https://github.com/tabbathacrouch/"
            target="_blank"
            rel="noreferrer"
            title="GitHub"
          >
            <img
              alt="GitHub icon"
              src={GitHubLogo}
              width="48px"
              height="48px"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/tabbathacrouch/"
            target="_blank"
            rel="noreferrer"
            title="LinkedIn"
          >
            <img
              alt="LinkedIn icon"
              src={LinkedInLogo}
              width="48px"
              height="48px"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;

import * as React from "react";
import "./Projects.css";
import { Button } from "../Button/Button";
import { openURL } from "../../utils/helperFunctions";
import { PROJECT_DATA } from "./ProjectData";

interface ProjectProps {
  project: PROJECT_DATA;
}

export const Project = ({ ...props }: ProjectProps) => {
  const { project } = props;

  return (
    <>
      <div className="row">
        <div className="project-text">
          <h3 className="project-title">{project.title}</h3>
          <div className="content">
            <p>{project.projectInfo}</p>
          </div>
        </div>
        <div className="project-buttons">
          <Button onPress={() => openURL(`${project.liveURL}`)}>APP</Button>
          <Button onPress={() => openURL(`${project.sourceCodeURL}`)}>
            CODE
          </Button>
        </div>
      </div>
      {project.id !== 2 && <hr />}
    </>
  );
};

import React, { useRef } from "react";
import { useButton } from "@react-aria/button";
import { AriaButtonProps } from "@react-types/button";
import { mergeProps } from "@react-aria/utils";

interface ButtonProps extends AriaButtonProps<"button"> {
  children: React.ReactNode;
}

export const Button = (props: ButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);
  const { children } = props;

  return (
    <button {...mergeProps(buttonProps, props)} ref={ref}>
      {children}
    </button>
  );
};

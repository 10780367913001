import * as React from "react";
import "./About.css";
import { Link } from "react-scroll";
import resume from "../../assets/Tabbatha Crouch - Resume.pdf";
import aria from "../../assets/withAria.webp";
import { openURL } from "../../utils/helperFunctions";

function About() {
  return (
    <div className="about">
      <h1 className="about-sectionTitle">About</h1>
      <div className="about-section">
        <div className="about-sectionInfo">
          <img
            className="about-image"
            src={aria}
            alt="great dane dog aria holding frisbee with tabbatha crouch"
            onClick={() =>
              openURL("https://www.instagram.com/aria_the_great_dane/")
            }
          />
          <ul className="about-infoList">
            <li>
              Self-taught Full Stack developer with{" "}
              <a href="https://react.dev/" target="_blank" rel="noreferrer">
                React
              </a>{" "}
              and{" "}
              <a
                href="https://reactnative.dev/"
                target="_blank"
                rel="noreferrer"
              >
                React-Native
              </a>{" "}
              experience
            </li>
            <li>
              Currently learning and working with{" "}
              <a
                href="https://www.typescriptlang.org/"
                target="_blank"
                rel="noreferrer"
              >
                TypeScript
              </a>
              ,{" "}
              <a
                href="https://styled-components.com/"
                target="_blank"
                rel="noreferrer"
              >
                styled components
              </a>
              , and{" "}
              <a href="https://graphql.org/" target="_blank" rel="noreferrer">
                GraphQL
              </a>
            </li>{" "}
            <li>
              Sometimes active on{" "}
              <a
                href="https://exercism.org/profiles/tabbathacrouch/"
                target="_blank"
                rel="noreferrer"
              >
                Exercism
              </a>{" "}
            </li>
            <li>Loves all pets &hearts; and plants &#x1F33B;</li>
          </ul>
        </div>
        <div className="about-buttons">
          <div>
            <Link
              href="projects"
              to="projects"
              activeClass="active"
              spy={true}
              smooth={true}
            >
              <button type="button" className="about-btn">
                PROJECTS
              </button>
            </Link>
          </div>
          <div>
            <a href={resume} target="_blank" rel="noreferrer">
              <button type="submit" className="about-btn">
                RESUME
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;

import * as React from "react";
import { Link } from "react-scroll";
import profile from "../../assets/profile.webp";
import "./Title.css";

function Title() {
  return (
    <div className="title-container">
      <img className="title-image" id="profile" src={profile} alt="Profile" />
      <div className="title-section">
        <div className="title-sectionTitle">
          Hey there, my name is Tabbatha Crouch.
          <br />
          I'm a Software Developer.
        </div>
        <div className="title-buttons">
          <Link
            href="about"
            to="about"
            activeClass="active"
            spy={true}
            smooth={true}
          >
            <button type="button" className="title-btn">
              ABOUT
            </button>
          </Link>
          <Link
            href="projects"
            to="projects"
            activeClass="active"
            spy={true}
            smooth={true}
          >
            <button type="button" className="title-btn">
              PROJECTS
            </button>
          </Link>
          <Link
            href="contact"
            to="contact"
            activeClass="active"
            spy={true}
            smooth={true}
          >
            <button type="button" className="title-btn">
              CONTACT
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Title;

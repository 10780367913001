export interface PROJECT_DATA {
  id: number;
  title: string;
  projectInfo: string;
  liveURL: string;
  sourceCodeURL: string;
}

export const ProjectData: PROJECT_DATA[] = [
  {
    id: 0,
    title: "Address Book",
    projectInfo:
      "This React app allows visitors to add contacts to an address book and search through the contacts using any field name! The main form contains form validation using the Yup and Formik libraries. Additionally, this app utilizes the Cypress framework for end-to-end testing.",
    liveURL: "https://address-book-jhnl.onrender.com/",
    sourceCodeURL: "https://github.com/tabbathacrouch/AddressBook/",
  },
  {
    id: 1,
    title: "React Chart Generator",
    projectInfo:
      "This React app utilizes three npm packages:react-chartjs-2,chart.js, andfile-saver. Create a custom vertical or horizontal bar chart, pie chart, or line chart and save the canvas as a .png file.",
    liveURL: "https://react-chart-generator.onrender.com/",
    sourceCodeURL: "https://github.com/tabbathacrouch/react-chart-generator/",
  },
  {
    id: 2,
    title: "Number Chart",
    projectInfo:
      "A single page react app that utilizes the useState hook to manage the state of an interactive number chart. Select the size (1-20, 1-50, or 1-100) and a color to illustrate patterns. Additionally, use the 'multiples' buttons to reveal the multiples of 2, 5, or 10.",
    liveURL: "https://number-chart.onrender.com/",
    sourceCodeURL: "https://github.com/tabbathacrouch/number-chart/",
  },
];

import * as React from "react";
import "./Projects.css";
import { PROJECT_DATA, ProjectData } from "./ProjectData";
import { Project } from "./Project";

const Projects = () => {
  return (
    <div className="projects">
      <h1 className="projects-sectionTitle">Projects</h1>
      <div className="projects-container">
        {ProjectData.map((project) => {
          return <Project key={project.id} project={project as PROJECT_DATA} />;
        })}
      </div>
    </div>
  );
};

export default Projects;
